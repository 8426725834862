import cn from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useWindowResize } from '../../hooks/useWindowResize';
import SketchIcon from './icons/Icon';
import SketchToolbar from './icons/Toolbar';
import drawIcon from '/../../assets/images/sketch/draw.svg';
import eraserIcon from '/../../assets/images/sketch/eraser.svg';
import rotateSketchIcon from '/../../assets/images/sketch/rotate-sketch-icon.png';
import TrashIcon from '/../../assets/images/sketch/trash.svg';
import TutorialIcon from '/../../assets/images/sketch/tutorial.svg';
// eslint-disable-next-line no-unused-vars
import {
	Coordinates,
	Icons,
	SceneTypes,
	SketchActionType,
	SketchContext,
} from './interfaces';
import './Sketch.scss';
import { Context } from './SketchContext';
import MessageWindow from './tutorial/MessageWindow';
import { addIconToMap, getSketchBackground, sketchToImage } from './Utils';

import {
	CollisionPage,
	TouchGesturePage,
	DragGesturePage,
	RotateGesturePage,
	TrashBinPage,
} from './modal-content/Pages';
// @ts-ignore
import { DrawCanvas } from './drawing-canvas';

const windowContent: {
	[key in number]: JSX.Element;
} = {
	1: <CollisionPage />,
	2: <TouchGesturePage />,
	3: <DragGesturePage />,
	4: <RotateGesturePage />,
	5: <TrashBinPage />,
	6: <TrashBinPage />,
};

interface SketchProps {
	coords?: Coordinates;
	sceneType: string | null;
	googleMapsApiKey: string;
	onConfirm: (sketchImg: string) => void;
}

const getIcons = (icons: Icons): JSX.Element[] => {
	if (!icons) {
		return [];
	}

	return Object.keys(icons).map((id, i) => (
		<SketchIcon
			key={icons[id].id}
			icon={icons[id].iconType}
			position={icons[id].position}
			iconId={icons[id].id}
			index={i}
			className={icons[id].className}
		/>
	));
};

const getMapUrl = (
	center: string,
	size: string,
	googleMapsApiKey: string,
): string =>
	`https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=${20}&key=${googleMapsApiKey}&size=${size}`;

const Sketch = ({
	coords,
	onConfirm,
	sceneType,
	googleMapsApiKey,
}: SketchProps): JSX.Element => {
	const ctx = useContext(Context) as SketchContext;
	const { dispatch } = ctx;

	const [mapUrl, setMapUrl] = useState<string>('');
	const [canDraw, setCanDraw] = useState<boolean>(false);
	const [canErase, setCanErase] = useState<boolean>(false);
	const [showInfoWindow, setShowInfoWindow] = useState<boolean>(
		!ctx.infoWindowShown,
	);
	const [showMapIcons, setShowMapIcons] = useState<boolean>(true);
	const [icons, setIcons] = useState<JSX.Element[]>([]);
	const [iwPage, setIwPage] = useState<number>(1);
	const [iwInterval, setIwInterval] = useState<NodeJS.Timeout | null>();
	const [processing, setProcessing] = useState<boolean>(false); // used to disable confirm button while capturing the sketch

	const sketchRef = useRef<HTMLDivElement>(null);

	const sketchHeight = sketchRef.current?.offsetHeight;
	const sketchWidth = sketchRef.current?.offsetWidth;

	const windowSize = useWindowResize();

	const center = `${coords?.lat},${coords?.lng}`;

	// Changes map size whenever the screen size changes to give a responsive-like experience.
	// Since we use Google Maps Static API, we need to request an image with the specific size we need.
	useEffect(() => {
		if (!sketchWidth || !sketchHeight) {
			return;
		}
		const scene = getSketchBackground(sceneType);
		if (scene) {
			setMapUrl(scene);
		} else {
			const newSize = `${sketchWidth}x${sketchHeight}`;
			setMapUrl(getMapUrl(center, newSize, googleMapsApiKey));
		}
		dispatch({
			type: SketchActionType.SET_MAP_SIZE,
			data: { height: sketchHeight, width: sketchWidth },
		}); // Icons needs the map size in order to be dragged around
	}, [
		windowSize,
		center,
		dispatch,
		sketchHeight,
		sketchWidth,
		sceneType,
		googleMapsApiKey,
	]);

	useEffect(() => {
		setIcons(getIcons(ctx.icons));
	}, [ctx.icons, sketchHeight, sketchWidth]);

	const addIcon = (iconType: string, className: string): void => {
		addIconToMap([...icons], iconType, setIcons, dispatch, className);
	};

	const setEnableRotate = (): void => {
		dispatch({ type: SketchActionType.SET_SKETCH_ROTATE_TRIGGER, data: true });
	};

	const confirm = async (): Promise<void> => {
		dispatch({ type: SketchActionType.SET_SKETCH_UNSELECTED_ICONS });
		setProcessing(true);
		setShowMapIcons(false);
		const sketchImg = await sketchToImage(dispatch);
		onConfirm(sketchImg);
		setProcessing(false);
		dispatch({ type: SketchActionType.CLEAR_ICONS });
		dispatch({
			type: SketchActionType.SET_SKETCH_INFO_WINDOW_SHOWN,
			data: false,
		});
	};

	const onInfoWindowDismiss = (): void => {
		setShowInfoWindow(false);
		dispatch({
			type: SketchActionType.SET_SKETCH_INFO_WINDOW_SHOWN,
			data: true,
		});
	};

	const cycleInfoWindowContent = (): void =>
		setIwPage((contentIndex: number) =>
			contentIndex !== Object.keys(windowContent).length ? contentIndex + 1 : 1,
		);

	useEffect(() => {
		if (showInfoWindow && !iwInterval) {
			setIwInterval(setInterval(cycleInfoWindowContent, 4000));
		} else if (!showInfoWindow && iwInterval) {
			clearInterval(iwInterval);
			setIwInterval(null); // clear interval reference
			setIwPage(1); // reset to first page
		}
	}, [showInfoWindow, iwInterval]);

	const carIcons = icons.filter((icon: JSX.Element) =>
		icon.props.icon.includes('car'),
	);
	const canContinue = carIcons.length > 0;
	let containerType = 'container-base1';

	if (sceneType?.toLowerCase() === SceneTypes.ROAD_I.toLowerCase()) {
		containerType = 'container-base2';
	}

	return (
		<React.Fragment>
			<div className="sketch-map">
				<div
					id="sketch-container"
					ref={sketchRef}
					style={{
						backgroundImage: `url(${mapUrl})`,
						backgroundPosition: 'center',
					}}
					className={containerType}
				>
					<div
						hidden={canDraw || canErase}
						className="drawing"
						style={{
							backgroundImage: `url(${ctx.draw?.dataUrl})`,
						}}
					/>
					{
						!showInfoWindow &&
							icons /* hide icons to prevent showing them on top of modal */
					}
					{showMapIcons && (
						<React.Fragment>
							<button
								className={cn('info', { active: showInfoWindow })}
								onClick={(): void => {
									setCanDraw(false);
									setCanErase(false);
									setShowInfoWindow(true);
								}}
							>
								<img
									hidden={!showMapIcons}
									className="help"
									src={TutorialIcon}
									alt="help"
								/>
							</button>
							<button
								className="rotateIcon"
								onClick={(): void => {
									setCanDraw(false);
									setCanErase(false);
									setEnableRotate();
								}}
							>
								<img src={rotateSketchIcon} alt="" />
							</button>
							<button
								className={cn('drawIcon', { active: canDraw })}
								onClick={(): void => {
									setCanErase(false);
									setCanDraw(!canDraw);
								}}
							>
								<img src={drawIcon} alt="" />
							</button>
							<button
								className={cn('eraserIcon', { active: canErase })}
								onClick={(): void => {
									setCanDraw(false);
									setCanErase(!canErase);
								}}
							>
								<img src={eraserIcon} alt="" />
							</button>
						</React.Fragment>
					)}
					{showMapIcons && (
						<button className="delete">
							<img hidden={!showMapIcons} src={TrashIcon} alt="delete icon" />
						</button>
					)}
					{(canDraw || canErase) && (
						<DrawCanvas
							style={{ background: 'none' }}
							onChange={(canvas: any): void => {
								dispatch({
									type: SketchActionType.SET_DRAW_DATA,
									data: {
										points: canvas.getSaveData(),
										dataUrl: canvas.canvasContainer.childNodes[1].toDataURL(),
									},
								});
							}}
							hideGrid
							brushRadius={canErase ? 8 : 2}
							canvasHeight={sketchHeight}
							canvasWidth={sketchWidth}
							lazyRadius={0}
							immediateLoading
							saveData={ctx.draw.points}
							erase={canErase}
						/>
					)}
				</div>
				<SketchToolbar
					addIcon={addIcon}
					toggleDraw={(value?: boolean): void =>
						setCanDraw(value !== undefined ? value : !canDraw)
					}
				/>
			</div>
			{showInfoWindow && (
				<MessageWindow dismiss={onInfoWindowDismiss} hideConfirmButton>
					{windowContent[iwPage]}
				</MessageWindow>
			)}

			<button
				className="confirm-button"
				disabled={!canContinue || processing}
				onClick={confirm}
			>
				{!processing ? 'Confirmar' : 'Capturando Croquis...'}
			</button>
		</React.Fragment>
	);
};

export default Sketch;
