// eslint-disable-next-line no-unused-vars
import { Action, SketchActionType, SketchContext } from './interfaces';
import {
	removeIconStatus,
	removeSketchIcon,
	setIconId,
	setIconStatus,
	setIconStatusRestore,
	setSketchIcons,
} from './Utils';

export const reducer = (
	state: SketchContext,
	action: Action,
): SketchContext => {
	switch (action.type) {
		case SketchActionType.HYDRATE_STATE:
			return { ...state, ...action.data };
		case SketchActionType.SET_ICON:
			return state;
		case SketchActionType.SET_DRAW_DATA:
			return { ...state, draw: action.data };
		case SketchActionType.SET_MAP_SIZE:
			return {
				...state,
				location: { ...state.location, mapSize: action.data },
			};
		case SketchActionType.ADD_SKETCH_ICON:
			return {
				...state,
				icons: { ...state.icons, ...action.data },
			};
		case SketchActionType.CLEAR_ICONS:
			return {
				...state,
				icons: {},
			};
		case SketchActionType.SET_SKETCH_SELECTED_ICON:
			return {
				...state,
				iconStatus: [...setIconStatus(state.iconStatus, action.data)],
				iconId: setIconId(action.data),
			};
		case SketchActionType.SET_SKETCH:
			return { ...state, ...action.data };
		case SketchActionType.SET_SKETCH_INFO_WINDOW_SHOWN:
			return { ...state, infoWindowShown: action.data };
		case SketchActionType.SET_SKETCH_ICONS:
			return {
				...state,
				icons: { ...setSketchIcons(state.icons, action.data) },
			};
		case SketchActionType.REMOVE_SKETCH_ICON:
			return {
				...state,
				icons: { ...removeSketchIcon(state.icons, action.data) },
				iconStatus: [...removeIconStatus(state.iconStatus, action.data)],
			};
		case SketchActionType.SET_SKETCH_ROTATE_TRIGGER:
			return { ...state, rotateIcon: action.data };
		case SketchActionType.SET_SKETCH_UNSELECTED_ICONS:
			return {
				...state,
				iconStatus: [...setIconStatusRestore(state.iconStatus)],
				iconId: setIconId({ id: -1, isSelected: false }),
			};

		default:
			return state;
	}
};
