import clsx from 'clsx';
import React from 'react';
import './styles.module.scss';

interface RoundButtonProps {
	checked: boolean;
	children: string;
	onClick: () => void;
}

const RoundButton = (props: RoundButtonProps): JSX.Element => {
	return (
		<button
			className={clsx('roundButton', { 'roundButton--checked': props.checked })}
			onClick={props.onClick}
		>
			{props.children}
		</button>
	);
};

export default RoundButton;
